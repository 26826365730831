import { NumericInput, Text } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { DateTime } from "luxon";
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import FileUploadCard from "components/elements/file-upload/FileUploadCard";
import "components/elements/file-upload/style.scss";
import { InputElementIcon } from "components/elements/index";
import { MAX_FILE_UPLOAD_SIZE_MB } from "constants/general";
import { HTTP, Response } from 'service';

const FileUploadMulti = (props) =>
{
    const { inputID, isEditable, isRequired, onUpdate, question, answer, responseLink, uploadDataObject } = props;
    const [displayFiles, setDisplayFiles] = useState([]);
    const files = useRef([]);
    const acceptedFormats = [
        '.jpg, .gif, .png, .doc, .docx, .pdf, .pps, .ppt, .pptx, .xls, .xlsx',
        'image/png', 'image/jpg', 'image/gif' // to allow for file extension variations
    ];
    const maxPerFileSize = MAX_FILE_UPLOAD_SIZE_MB * 1024 ** 2;

    useEffect(() => {
        // get saved files
        if(answer && responseLink && answer[responseLink]) {
            const savedFiles = answer[responseLink];
            savedFiles.forEach(file => {
                const filesList = Object.assign([], files.current);
                // download link is not available when switching tabs, so hardcoding to /download
                const downloadLink = Response.getLink(file, 'self') + '/download';
                const savedFile = {
                    mimeType: file.mimeType,
                    isUploaded: true,
                    href: Response.getLink(file, 'self'),
                    _links: { self: file._links.self },
                    title: file.title,
                    download: downloadLink
                };
                filesList.push(savedFile);
                updateFiles(filesList, true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFiles = (filesList, initialise = false) => {
        files.current = filesList;

        // only put uploaded files to answer
        const savedFiles = files.current.filter(file => file.href && file.href.length !== 0);
        typeof onUpdate === 'function' && onUpdate({ files: savedFiles }, initialise);
        setDisplayFiles(files.current);
    }

    const onDelete = (deletedFile) => {
        const filesList = files.current.filter(file => file.href !== deletedFile.href);
        updateFiles(filesList);
    }

    const onDrop = (acceptedFiles, fileRejections) => {
        fileRejections.forEach(file => {
            const rejectMessage = file.errors[0].code === `file-too-large` ? `File exceedes size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB` : file.errors[0].message;
            toast.error(`${file.file.name} not uploaded (${rejectMessage})`);
        });

        acceptedFiles.forEach(file => {
            const filesList = Object.assign([], files.current);
            const addedFile = {
                mimeType: file.type,
                isUploaded: false,
                href: null,
                title: file.name,
                download: null,
                hasErrors: false,
                errorText: null,
            };
            filesList.push(addedFile);
            updateFiles(filesList);

            HTTP
                .upload(
                    file,
                    Object.assign({}, uploadDataObject, {
                        title: file.name,
                    }),
                    (progressEvent, value) => { addedFile.progress = progressEvent.loaded / progressEvent.total; }
                )
                .then((response) => {
                    if (response) {
                        Object.assign(addedFile, {
                            isUploaded: true,
                            href: Response.getLink(response.data, 'self'),
                            _links: { self: response.data._links.self },
                            title: response.data.title,
                            download: Response.getLink(response.data, 'download'),
                        });
                        // force update
                        updateFiles(Object.assign([], files.current));

                        return true;
                    }

                    addedFile.hasErrors = true;
                    addedFile.errorText = "Unable to upload file";
                    toast.error('Unable to upload file');
                    // force update
                    updateFiles(Object.assign([], files.current));

                    return false;
                })
                .catch(error => {
                    addedFile.hasErrors = true;
                    let response = error.response;

                    if (error.request && error.request.status === 413) {
                        addedFile.errorText = `File exceedes size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB`;
                    } else if (response?.data[0].hasOwnProperty('message')) {
                        addedFile.errorText = response.data[0].message ;
                    } else {
                        addedFile.errorText = error.message;
                    }

                    toast.error('Unable to upload file');
                    // force update
                    updateFiles(Object.assign([], files.current));
                    return false;
                });
        });
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: acceptedFormats.join(", "),
        maxSize: maxPerFileSize,
        minSize: 1
    });

    const fileInputField = () => {
        if (isEditable) {
            return (
                <div className="file-upload-wrap">
                    <div {...getRootProps({
                        className: 'file-upload'
                    })}>
                        <input {...getInputProps({
                            id: inputID,
                            required: isRequired && !displayFiles.length
                        })} />
                        {
                            isDragActive ?
                                <div className="dragging-frame">Drop the files here ...</div> :
                                <div className="drag-frame">Drag your files and drop them here, or <u>click to select files</u></div>
                        }
                    </div>
                    <div className="file-list">
                        {
                            displayFiles.map( (file, index) => (
                                <FileUploadCard key={ `${inputID}_child_${index}` }
                                    { ...file }
                                    inputID={ inputID }
                                    isEditable={ isEditable }
                                    onDelete={ () => { onDelete(file) } }
                                />
                            ))
                        }
                    </div>
                </div>
            );
        } else if (displayFiles.length) {
            return (
                <div className="file-upload-wrap">
                    <div className="file-list">
                        {
                            displayFiles.map( (file, index) => (
                                <FileUploadCard key={ `${inputID}_child_${index}` }
                                    { ...file }
                                    inputID={ inputID }
                                    isEditable={ isEditable }
                                    onDelete={ () => { onDelete(file) } }
                                />
                            ))
                        }
                    </div>
                </div>
            )
        }


        return (
            <Text className="bp3-fill display-text display-text-input">
                <em>No Files Uploaded</em>
            </Text>
        );
    }

    const expiryInput = () => {
        let defaultExpiresAt = null;
        let minDate = DateTime.local().set({hours: 0, minutes: 0, seconds: 0}).toJSDate();
        let maxDate = DateTime.local().plus({years: 5}).toJSDate();

        if (answer) {
            if (typeof answer.expiresAt === 'string' &&
                Date.parse(answer.expiresAt)) {
                defaultExpiresAt = new Date(answer.expiresAt);
            } else if (typeof answer.expiresAt === 'object') {
                defaultExpiresAt = answer.expiresAt;
            }
        }

        if (defaultExpiresAt !== null &&
            defaultExpiresAt < minDate) {
            defaultExpiresAt = null;
        }

        return question.expiryRequirement === 0 ? null : (
            <div className="date-input">
                <label
                    className={
                        'bp3-label bp3-fill' +
                        (question.expiryRequirement === 2 ? ' required' : '')
                    }
                    htmlFor={`expiry_${question.id}`}
                >
                    Expiration Date of File
                </label>

                <DateInput
                    name={question.id}
                    minDate={minDate}
                    maxDate={maxDate}
                    initialMonth={minDate}
                    parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                    formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                    popoverProps={{ position: 'bottom', fill: true }}
                    inputProps={{
                        fill: true,
                        id: 'expiry_' + question.id,
                        rightElement: InputElementIcon(
                            'Select a date...',
                            'calendar-alt'
                        ),
                    }}
                    onChange={(newDate) => {
                        newDate = new Date(+newDate + 1000);
                        typeof onUpdate === 'function' &&
                            onUpdate({
                                expiresAt: newDate,
                                files: files.current,
                            });
                    }}
                    value={defaultExpiresAt}
                    disabled={!isEditable}
                />
            </div>
        );
    }

    const numberInput = () => {
        let defaultNumber = answer && answer.value ? answer.value : '';

        return !question.hasOwnProperty('numberRequirement') || question.numberRequirement === 0  ?
            null :
            (
                <div className="number-input">
                    <label
                        className={ 'bp3-label bp3-fill' + (question.numberRequirement === 2 ? ' required': '') }
                        htmlFor={ `number_${question.id}` }>
                        Number
                    </label>

                    <NumericInput id={ `number_${question.id}` }
                        name={ question.id }
                        fill={ true }
                        min={ question.minValue }
                        max={ question.maxValue }
                        majorStepSize={ null }
                        minorStepSize={ null }
                        stepSize={ question.step }
                        value={ defaultNumber }
                        onValueChange={ (numericValue, stringValue) => typeof onUpdate === 'function' && onUpdate({value: stringValue, files: files.current}) }
                        disabled={ !isEditable }/>
                </div>
            )
    }



    return <>
        { fileInputField() }

        { expiryInput() }

        { numberInput() }
    </>
}

FileUploadMulti.propTypes = {
    file: PropTypes.object,
    inputID: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    isRequired: PropTypes.bool,
    onUpdate: PropTypes.func,
    question: PropTypes.object.isRequired,
    answer: PropTypes.object.isRequired,
    responseLink: PropTypes.string.isRequired,
    uploadDataObject: PropTypes.object.isRequired,
};

FileUploadMulti.defaultProps = {
    isEditable: false,
    isRequired: false,
};

export default FileUploadMulti;
