import { Tab, Tabs } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Switch } from 'react-router-dom';

import {
    clearChanges,
    clearErrors,
    loadAssessmentData,
} from 'action/assessments';
import ServicesBanner from 'components/assessments/servicesBanner/ServicesBanner';
import Comments from 'components/assessments/tabs/comments/Comments';
import AssessmentTypeInstanceHistory from 'components/assessments/tabs/history/AssessmentTypeInstanceHistory';
import QuestionsForm from 'components/assessments/tabs/questions/QuestionsForm';
import { ProtectedRoute } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { isAssessorReviewer, parseDateFormat } from 'helpers/helpers';
import { InstanceService, Response } from 'service';

const AssessmentTypeAccreditation = (props) => {
    // load props/params
    const { match, mode } = props;
    const assessmentInstanceId = match.params.id;
    const [commentsData, setCommentsData] = useState([]);
    const [latestAssessments, setLatestAssessments] = useState([]);

    // add hooks to redux stores
    const rootResponse = useSelector((state) => state.root.response);
    const assessmentLoadedState = useSelector((state) => state.assessments);

    const dispatch = useDispatch();

    // on page load, reset saved state, load state from api
    useEffect(() => {
        reloadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getComments = async () => {
        const comments = await InstanceService.getComments(
            assessmentTypeInstance
        );
        setCommentsData(comments);
    };

    const getLatestAssessments = async () => {
        const instances = await InstanceService.getLatestAssessments(
            assessmentTypeInstance
        );
        setLatestAssessments(instances);
    };

    // reloadData method passed to submit methods to reload form
    const reloadData = () => {
        dispatch(clearErrors());
        dispatch(clearChanges());
        dispatch(loadAssessmentData(assessmentInstanceId, rootResponse, mode));
        getComments();
        getLatestAssessments();
    };

    const companyName = () => {
        const company = assessmentTypeInstance?._links.company;
        const companyName = company?.registeredCompanyName;

        if (company?.tradingName?.length) {
            return companyName + ` (Trading as ${company.tradingName})`;
        }

        return companyName;
    };

    const companyAddress = () => {
        const company = assessmentTypeInstance?._links.company;

        if (!company) {
            return null;
        }

        const addressInfo = [];

        const companyFields = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'town',
            'county',
            'postCode',
            'country',
        ];

        companyFields.forEach((field) => {
            if (company[field]) {
                addressInfo.push(company[field]);
            }
        });

        return <span>{addressInfo.join(', ')}</span>;
    };

    const assessmentCards = () => {
        return latestAssessments.map((instance, key) => {
            return (
                <div className="mb-1 me-2 " key={'instance-' + key}>
                    {instance.assessmentShortName} ( expiry:{' '}
                    {parseDateFormat(instance.expiryDate, 'Do MMM YYYY')} )
                </div>
            );
        });
    };

    let { topicCollection, assessmentTypeInstance, isLoading } =
        assessmentLoadedState;

    return (
        <div className="page">
            <div className="page-wrapper no-styling AccreditationAnswers">
                <Loading isLoading={isLoading}>
                    <NoResultsFound count={topicCollection?.count}>
                        <ServicesBanner
                            assessmentTypeInstance={assessmentTypeInstance}
                        />
                        <div className="inner">
                            <div className="page-header">
                                <h1 className="page-title">
                                    {Response.getLinkAttribute(
                                        assessmentTypeInstance,
                                        'assessment-type',
                                        'name'
                                    )}
                                    {isAssessorReviewer() &&
                                        ' - ' +
                                            Response.getLinkAttribute(
                                                assessmentTypeInstance,
                                                'assessment-type',
                                                'version'
                                            )}
                                </h1>
                            </div>
                            {(isAssessorReviewer() || mode === 'review') && (
                                <div className="info pb-4">
                                    <div>
                                        <span>Company name: </span>

                                        <Link
                                            to={
                                                '/service-providers/' +
                                                Response.getLinkAttribute(
                                                    assessmentTypeInstance,
                                                    'company',
                                                    'id'
                                                )
                                            }
                                        >
                                            {companyName()}
                                        </Link>
                                    </div>
                                    <div>
                                        <span>Company size: </span>
                                        {Response.getLinkAttribute(
                                            assessmentTypeInstance,
                                            'company',
                                            'organisationSize'
                                        )}
                                    </div>
                                    <div>
                                        <span>Company address: </span>
                                        {companyAddress()}
                                    </div>
                                    {!!latestAssessments?.length && (
                                        <div>
                                            <span>Company Assessments:</span>
                                            <div className="ps-4 pt-1">
                                                {assessmentCards()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <Tabs
                                renderActiveTabPanelOnly={true}
                                className="parent"
                            >
                                <Tab id={'/questions'}>
                                    <Link to={`${match.url}/questions`}>
                                        Question Topics
                                    </Link>
                                </Tab>
                                {Response.getLink(
                                    assessmentTypeInstance,
                                    'events'
                                ) && (
                                    <Tab id={'/history'}>
                                        <Link to={`${match.url}/history`}>
                                            History
                                        </Link>
                                    </Tab>
                                )}
                                {(commentsData.length > 0 ||
                                    Response.getLink(
                                        assessmentTypeInstance,
                                        'add-comments'
                                    )) && (
                                    <Tab id={'/comments'}>
                                        <Link to={`${match.url}/comments`}>
                                            Additional Comments
                                        </Link>
                                    </Tab>
                                )}
                            </Tabs>
                            <div className="TabContent">
                                <Switch>
                                    <ProtectedRoute
                                        path={`${match.url}/questions`}
                                        component={QuestionsForm}
                                        mode={mode}
                                        reloadData={reloadData}
                                    />
                                    <ProtectedRoute
                                        path={`${match.url}/history`}
                                        component={
                                            AssessmentTypeInstanceHistory
                                        }
                                        assessmentTypeInstanceResponse={
                                            assessmentTypeInstance
                                        }
                                    />
                                    <ProtectedRoute
                                        path={`${match.url}/comments`}
                                        component={Comments}
                                        assessmentTypeInstance={
                                            assessmentTypeInstance
                                        }
                                    />
                                    <Redirect to={`${match.url}/questions`} />
                                </Switch>
                            </div>
                        </div>
                    </NoResultsFound>
                </Loading>
            </div>
        </div>
    );
};

export default AssessmentTypeAccreditation;
