import { FileInput, Text } from '@blueprintjs/core';
import { Component } from 'react';
import { toast } from 'react-toastify';

import { FileDownload, ProgressBar } from 'components/elements';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import PropTypes from 'prop-types';
import { HTTP, Response } from 'service';

class FileUpload extends Component {
    static propTypes = {
        accept: PropTypes.string,
        inputID: PropTypes.string.isRequired,
        isEditable: PropTypes.bool,
        isRequired: PropTypes.bool,
        isImage: PropTypes.bool,
        onUpdate: PropTypes.func,
        onUpload: PropTypes.func.isRequired,
        response: PropTypes.object.isRequired,
        responseLink: PropTypes.string.isRequired,
        uploadDataObject: PropTypes.object.isRequired,
        updateFileError: PropTypes.func,
        uploadUrl: PropTypes.string,
    };

    static defaultProps = {
        isEditable: false,
        isRequired: false,
        isImage: false,
        uploadUrl: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isUploading: false,
        };
    }

    render() {
        const { isUploading } = this.state;
        const {
            accept = '',
            inputID,
            isImage = false,
            isEditable,
            isRequired,
            onUpdate,
            onUpload,
            response,
            responseLink,
            uploadDataObject,
            updateFileError = () => {},
            uploadUrl = null,
        } = this.props;

        if (isUploading) {
            return <ProgressBar />;
        } else if (
            isEditable &&
            Response.getLink(response, responseLink) === null
        ) {
            return (
                <>
                    <FileInput
                        id={inputID}
                        fill={true}
                        inputProps={{ required: isRequired, accept }}
                        onInputChange={(event) => {
                            if (event.target.files[0]) {
                                this.setState({ isUploading: true });

                                HTTP.upload(
                                    event.target.files[0],

                                    Object.assign({}, uploadDataObject, {
                                        title: event.target.files[0].name,
                                        isImage,
                                    }),
                                    (progressEvent, value) => {},
                                    {},
                                    uploadUrl
                                )
                                    .then((response) => {
                                        this.setState({ isUploading: false });

                                        updateFileError(null);

                                        if (response) {
                                            typeof onUpload === 'function' &&
                                                onUpload(response);

                                            return true;
                                        }

                                        return false;
                                    })
                                    .catch((error) => {
                                        this.setState({ isUploading: false });

                                        let response = error.response;

                                        if (response.status === 413) {
                                            updateFileError(
                                                `File exceeded size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB`
                                            );
                                        } else if (
                                            response?.data[0].hasOwnProperty(
                                                'message'
                                            )
                                        ) {
                                            updateFileError(
                                                response.data[0].message
                                            );
                                        }

                                        toast.error('Unable to upload file');

                                        return false;
                                    });
                            }
                        }}
                    />
                </>
            );
        } else if (Response.getLink(response, responseLink) !== null) {
            return (
                <FileDownload
                    response={response}
                    isEditable={isEditable}
                    link={responseLink}
                    onUpdate={() => {
                        typeof onUpdate === 'function' && onUpdate();
                    }}
                />
            );
        }

        return (
            <Text className="bp3-fill display-text display-text-input">
                <em>No Files Uploaded</em>
            </Text>
        );
    }
}

export default FileUpload;
