import {
    ComponentProps,
    SyntheticEvent,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { Company, ListCollection } from 'interface';
import {
    CampaignUpdatesListFilter,
    InviteeStatus,
} from 'interface/Client/Campaign';
import { toast } from 'react-toastify';
import { CompanyService } from 'service';
import CampaignUpdateDialog from './CampaignUpdateDialog';

interface AllCompanyUpdatesProps extends ComponentProps<any> {
    company?: Company;
    isOpen: boolean;
    onClose: Function;
    reloadData?: Function;
    showFollowUpDate: boolean;
    showIsDeclined: boolean;
}

const AllCompanyUpdates = (props: AllCompanyUpdatesProps) => {
    const {
        company,
        isOpen,
        onClose,
        reloadData,
        showFollowUpDate,
        showIsDeclined,
        followUpDate
    } = props;

    const initialState = {
        dialogOpen: !!isOpen,
        isLoading: true,
        statusMessage: '',
        isDeclined: false,
        updateType: null,
        followUpDate: null,
        clearFollowUpDate: true
    }

    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        initialState
    );

    const [statuses, setStatuses] = useState<ListCollection<InviteeStatus>>(
        {} as ListCollection<InviteeStatus>
    );

    useEffect(() => {
        if (typeof followUpDate === 'string') {
            setState({ followUpDate: new Date(followUpDate) });
        }
    }, [followUpDate]);

    useEffect(() => {
        (async () => {
            setState({ isLoading: true });
            await load();
            setState({ dialogOpen: !!isOpen, isLoading: false });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, isOpen]);

    // cleanup
    useEffect(
        () => {
            return () => {
                setStatuses({} as ListCollection<InviteeStatus>);
            }
        },
        []
    );

    const load = async () => {
        if (!company) {
            return;
        }

        const data = await CompanyService.getInviteeStatuses(
            company,
            {} as CampaignUpdatesListFilter
        );

        data && setStatuses(data);
    };

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            statusMessage: '',
            isLoading: false,
            updateType: '',
            isDeclined: false,
            followUpDate: null
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!state.updateType) {
            toast.error('Please select a update type');
            return;
        }

        //use invitee and submit here
        setState({ isLoading: true });

        const { statusMessage, isDeclined, updateType, followUpDate, clearFollowUpDate } = state;

        if (company) {
            const response = await CompanyService.postCompanyInviteeStatus(
                company,
                {
                    statusMessage,
                    isDeclined,
                    updateType,
                    optionalFollowUpDate: clearFollowUpDate ? null : followUpDate,
                }
            );

            response && toast.success('Status has been updated successfully.');
        }


        if (typeof reloadData === 'function') {
            reloadData();
        }

        dialogClose({} as SyntheticEvent);
    };

    const handleOnChange = (key: string, value: any) => {
        setState({ [key]: value });
    };

    return (
        <CampaignUpdateDialog
            onClose={dialogClose}
            updates={statuses}
            isOpen={state.dialogOpen}
            title={`View Campaign Updates - ${company?.registeredCompanyName || ''}`}
            onChange={handleOnChange}
            initialData={state}
            isLoading={state.isLoading}
            onConfirm={dialogConfirm}
            isGlobalUpdate={true}
            showFollowUpDate={showFollowUpDate}
            showIsDeclined={showIsDeclined}
        />
    );
};

export default AllCompanyUpdates;
