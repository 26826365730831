import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { List } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { parseDateFormat, toMoney } from 'helpers/helpers';
import { ListCollection } from 'interface';
import {
    CampaignUpdate,
    CampaignUpdatesListFilter,
    CampaignUpdatesSearchFilter,
    purchaseTypeFilterOptions,
} from 'interface/Client/Campaign';
import { CampaignService, ClientService, UserService } from 'service';

const PurchasesFromChasersList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const defaultFilters = {
        order: '-updatedOn',
    };

    const [updates, setUpdates] = useState<ListCollection<CampaignUpdate>>(
        {} as ListCollection<CampaignUpdate>
    );

    const [listFilter, setListFilter] = useState<CampaignUpdatesListFilter>({
        ...defaultFilters,
    } as CampaignUpdatesListFilter);

    const defaultSearchFilters = {
        campaignSelect: {
            type: 'single-select',
            label: 'Campaign Name',
            key: 'campaign',
            placeholder: '',
        },
        client: {
            type: 'single-select',
            label: 'Client Name',
            key: 'client',
            defaultValue: NONE_SELECTED_OPTION,
            options: [],
        },
        updatedBy: {
            type: 'single-select',
            label: 'User',
            key: 'chasedBy',
            defaultValue: NONE_SELECTED_OPTION,
            options: [],
        },
        purchaseType: {
            type: 'single-select',
            label: 'Purchase Type',
            key: 'purchaseType',
            defaultValue: NONE_SELECTED_OPTION,
            options: purchaseTypeFilterOptions,
        },
    };

    const columns = [
        {
            name: 'Client Name',
            property: 'clientName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Campaign Name',
            property: 'campaignName',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Service Provider Name',
            property: 'serviceProviderName',
            sortable: true,
            grow: 3,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.serviceProviderName ? row.serviceProviderName : 'TBC',
        },
        {
            name: 'Assessment Purchased / Supply Chain Joined',
            property: 'updateDetails',
            sortable: true,
            grow: 3,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.updateDetails ? row.updateDetails : 'N/A',
        },
        {
            name: 'Purchase Type',
            property: 'purchaseType',
            grow: 2
        },
        {
            name: 'Date Of Purchase',
            property: 'updatedOn',
            type: 'callback',
            callback: (row: CampaignUpdate) => parseDateFormat(row.updatedOn),
            sortable: true,
            grow: 3,
        },
        {
            name: 'Chased By',
            property: 'chasedBy',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Date of last chase',
            property: 'chaseAt',
            type: 'callback',
            callback: (row: CampaignUpdate) => parseDateFormat(row.chasedAt),
            sortable: true,
            grow: 3,
        },
        {
            name: 'Value of Sale',
            property: 'valueOfSale',
            type: 'callback',
            callback: (row: CampaignUpdate) => {
                return toMoney(row.valueOfSale);
            },
            sortable: true,
            grow: 2,
        },
    ];

    const [searchFilters, setSearchFilters] =
        useState<CampaignUpdatesSearchFilter>(defaultSearchFilters);

    const downloadHandler = async (
        downloadFilters: CampaignUpdatesListFilter
    ) => {
        await CampaignService.downloadPurchaseUpdatesList({
            ...listFilter,
            ...downloadFilters,
        });

        toast.success('Purchases with Sales Involved Downloaded');
    };

    useEffect(() => {
        (async () => {
            const copySearchFilters = { ...searchFilters };

            if (typeof copySearchFilters.campaignSelect !== 'undefined') {
                copySearchFilters.campaignSelect.options =
                    await CampaignService.getCampaignsDropdown();
            }

            copySearchFilters.client.options =
                await ClientService.getClientListForDropdown();

            copySearchFilters.updatedBy.options =
                await UserService.getChaseUsersDropdown();

            setSearchFilters(copySearchFilters);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async (filter: CampaignUpdatesListFilter) => {
        setIsLoading(true);

        const data = await CampaignService.loadUpdatesList(filter, true);
        setUpdates(data);

        setListFilter({
            ...defaultFilters,
            ...filter,
            limit: data.limit,
            page: data.page
        });

        setIsLoading(false);
    };

    return (
        <div className="campaignUpdatesList">
            <List
                key="updates-table"
                title="Purchases where Sales involved"
                columns={columns}
                load={(filter: CampaignUpdatesListFilter) => load(filter)}
                collection={updates}
                filter={listFilter}
                defaultFilter={defaultFilters}
                filters={searchFilters}
                isLoading={isLoading}
                noRecordsFoundText="There are no purchase updates matching your filters"
                downloadHandler={downloadHandler}
            />
        </div>
    );
};

export default withRouter(PurchasesFromChasersList);
