import { elipsesString, parseDateFormat } from "helpers/helpers";

import '../index.scss';

interface InviteeUpdateInfoProps {
    canUpdate?: boolean,
    isDeclined?: boolean,
    type?: string,
    updatedBy?: string,
    updatedAt?: string,
    message?: string,
    onClick: (event: any) => void
}

const InviteeUpdateInformation = (props: InviteeUpdateInfoProps) => {
    const {
        canUpdate = false,
        isDeclined = false,
        type = "",
        updatedBy = "",
        updatedAt = "",
        message = "",
        onClick
    } = props;

    const getStatusMessage = (message?: string) => {
        if (!message) {
            return null;
        }

        if (message.length > 50) {
            return elipsesString(message);
        }

        return message;
    }

    const getUpdateButton = (text: string) => {
        return (
            <button
                onClick={(event) => onClick(event)}
                className="clear-button success"
            >
                {text ? text : ''}
            </button>
        )
    }

    const getUpdateInfo = () => {
        const updateInfo = [];

        if (type) {
            updateInfo.push(type);
        }

        if (updatedBy) {
            updateInfo.push(updatedBy);
        }

        if (!updateInfo.length) {
            return null;
        }

        return (
            <p className="wrap-word bold update-info-text">
                {updateInfo.join(" - ")}
            </p>
        );
    }

    if (updatedAt) {
        let updateStatus = parseDateFormat(updatedAt);

        if (isDeclined) {
            updateStatus += " - Declined";
        }

        const containerOnClick = canUpdate
            ? (e: any) => onClick(e)
            : undefined

        return (
            <div
                className={`${containerOnClick ? 'update-info-clickable' : ''}`}
                onClick={containerOnClick}
            >
                <span className={isDeclined ? 'error' : ''}>
                    {updateStatus}
                </span>
                {getUpdateInfo()}
                {message && (
                    <p className="wrap-word">
                        {getStatusMessage(message)}
                    </p>
                )}
            </div>
        );
    }

    if (canUpdate) {
        return getUpdateButton('Update');
    }

    return <></>;
}

export default InviteeUpdateInformation;