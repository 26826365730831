import { Checkbox, FormGroup, Switch, TextArea } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useReducer } from 'react';

import { HTMLSelect } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { CAMPAINGN_UPDATE_TYPE_OPTIONS } from 'interface/Client/Campaign';

interface InviteeUpdateFormProps {
    onChange: (key: string, value: any) => void;
    initialState: object;
    isGlobalUpdate: boolean;
    showIsDeclined: boolean;
    showFollowUpDate: boolean;
}

const InviteeUpdateForm = (props: InviteeUpdateFormProps) => {
    const {
        initialState,
        isGlobalUpdate,
        onChange,
        showFollowUpDate,
        showIsDeclined,
    } = props;

    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            statusMessage: '',
            followUpDate: null,
            updateType: null,
            isDeclined: false,
            clearFollowUpDate: true,
            isGlobalUpdate: isGlobalUpdate,
        }
    );

    useEffect(() => {
        setState(initialState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateState = (key: string, value: any) => {
        setState({ [key]: value });
        onChange(key, value);
    };

    const renderFollowUpDate = () => {
        if (state.clearFollowUpDate) {
            return null;
        }

        let maxDate = DateTime.local().plus({ years: 3 }).toJSDate();
        let minDate = DateTime.local().toJSDate();

        return (
            <FormGroup
                label="Optional follow-up date"
                inline
                className="form-fill"
            >
                <DateInput
                    parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                    formatDate={(date) =>
                        DateTime.fromJSDate(date).toLocaleString()
                    }
                    popoverProps={{ position: 'bottom', fill: true }}
                    inputProps={{
                        fill: true,
                        id: 'startDate',
                    }}
                    onChange={(newDate) => {
                        updateState('followUpDate', new Date(+newDate + 1000));
                    }}
                    value={state.followUpDate}
                    disabled={state.clearFollowUpDate}
                    maxDate={maxDate}
                    minDate={minDate}
                />
            </FormGroup>
        );
    };

    return (
        <>
            <label className="bp3-label" htmlFor="updateText">
                {isGlobalUpdate
                    ? "Provide an update, shown in all of this company's campaigns."
                    : 'Provide an update on the invitee, or mark them as declined.'}
            </label>

            <FormGroup
                label="Type of Update"
                key="update-type"
                inline
                className="form-fill required"
                labelFor="update-type"
            >
                <HTMLSelect
                    id="update-type"
                    fill
                    required
                    defaultValue={NONE_SELECTED_OPTION.value}
                    onChange={(e: any) => {
                        updateState('updateType', e.target.value);
                    }}
                    options={[
                        NONE_SELECTED_OPTION,
                        ...CAMPAINGN_UPDATE_TYPE_OPTIONS,
                    ]}
                />
            </FormGroup>

            <FormGroup inline={true} className="form-fill">
                <TextArea
                    id="updateText"
                    placeholder="Enter Update..."
                    onChange={(e: any) =>
                        updateState('statusMessage', e.target.value)
                    }
                    value={state.statusMessage}
                    className="full-width no-resize"
                    rows={8}
                />
            </FormGroup>

            {showFollowUpDate && renderFollowUpDate()}

            {showIsDeclined && (
                <FormGroup
                    className={'bp3-inline form-fill invitee-update-flex'}
                >
                    <div className="invitee-update-flex">
                        <Switch
                            id="isDeclined"
                            label="Mark as declined?"
                            alignIndicator={'left'}
                            checked={state.isDeclined}
                            onChange={(e: any) => {
                                updateState('isDeclined', e.target.checked);
                            }}
                        />
                        {showFollowUpDate && (
                            <Checkbox
                                checked={state.clearFollowUpDate}
                                onChange={() =>
                                    updateState(
                                        'clearFollowUpDate',
                                        !state.clearFollowUpDate
                                    )
                                }
                            >
                                Clear follow up date
                            </Checkbox>
                        )}
                    </div>
                </FormGroup>
            )}
        </>
    );
};

export default InviteeUpdateForm;
