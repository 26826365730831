interface ServiceProviderHeaderProps {
    name: string;
    county?: string;
    isSuspended?: boolean;
    isActive?: boolean;
    isLoading?: boolean;
}

const ServiceProviderHeading = (props: ServiceProviderHeaderProps) => {
    const {
        name,
        county,
        isSuspended = false,
        isActive = true,
        isLoading = false
    } = props;

    if (isLoading) {
        return null;
    }

    return (
        <h1 className="page-title">
            <span className="v-align-middle">
                {name}
                {county ? `, ${county}` : null}
                {isSuspended && (
                    <span className="company-suspended-title">
                        {' '}
                        suspended
                    </span>
                )}
            </span>

            {!!isActive ? (
                <img
                    className="icon small"
                    src="/assets/custom-icons/company-active.svg"
                    alt="icon"
                    title="active"
                />
            ) : (
                <img
                    className="icon small"
                    src="/assets/custom-icons/company-inactive.svg"
                    alt="icon"
                    title="inactive"
                />
            )}
        </h1>
    );
}

export default ServiceProviderHeading;
